import React, { useEffect, useMemo, useState } from 'react';
import _flatten from 'lodash/flatten';
import { MobXProviderContext } from 'mobx-react';
import {
  FastField,
  Field,
  FormikProps,
  FastFieldProps,
  FieldProps,
} from 'formik';

import { RestaurantUtils } from '@lib/common';
import { FreeItemPromo, ConventionalDiscountPromo } from '../promos/type';
import {
  FormGroup,
  ButtonGroup,
  FormTreeSelect,
  Button,
  SelectAdv,
} from '@lib/components';
import { ConditionTiers } from './condition-tiers';
import { nanoid } from 'nanoid';

type Props = {
  form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>;
  getFieldError: (
    form: FormikProps<FreeItemPromo | ConventionalDiscountPromo>,
    field: string
  ) => T.ObjectAny | string | undefined | null;
};

const getDeals = (store: any, brandId: string) => {
  return store.api.getWalletlyDeal({
    brandId
  });
};

export function ConditionTab({ form, getFieldError }: Props) {
  const { store } = React.useContext(MobXProviderContext);
  const [deals, setDeals] = useState([]);
  const restaurant = store.restaurant!;
  const loyalty_providers: T.Schema.Restaurant.RestaurantSettings['loyalty_providers'] = store.restaurant.settings?.loyalty_providers;
  const menuTree = useMemo(() => {
    return RestaurantUtils.menu.getTreeFilter(store.restaurant!, () => true);
  }, [store.restaurant]);

  useEffect(() => {
    if (loyalty_providers?.walletly?.enabled && loyalty_providers?.walletly?.brand_id) {
      getDeals(store, loyalty_providers?.walletly?.brand_id).then((data: any) => {
        if (data.data) setDeals(data.data);
      });
    }
  }, []);
  
  const conditionType = form.values.condition.type;

  const normalizeTreeData = (input: any) => {
    if (!input) return [];

    const restaurant: T.Schema.Restaurant.RestaurantSchema = store.restaurant!;
    const menus = restaurant.menus;
    let dishIds: string[] = [];

    for (const menuId of Object.keys(input)) {
      const selection = input[menuId];
      const menu = menus.find(m => m._id === menuId);
      if (!menu) continue;

      if (selection.menu.length > 0) {
        menu.categories.forEach(category =>
          category.dishes.forEach(dish => dishIds.push(dish._id))
        );
        continue;
      }

      if (selection.category.length > 0) {
        menu.categories.forEach(category => {
          if (selection.category.includes(category._id)) {
            category.dishes.forEach(dish => dishIds.push(dish._id));
          }
        });
      }

      if (selection.dish.length > 0) {
        dishIds = dishIds.concat(selection.dish);
      }
    }

    return dishIds;
  };

  return (
    <div className="p-4">
      <Field name="condition.type">
        {({ field }: FastFieldProps) => (
          <FormGroup
            title="Type"
            help="Pick eligibility criteria to be either the cart quantity or the cart total"
            error={getFieldError(form, 'condition.type')}
          >
            <ButtonGroup
              size={'xs'}
              selected={field.value}
              options={[
                {
                  value: 'item_quantity',
                  name: 'Item Quantity',
                },
                {
                  value: 'order_amount',
                  name: 'Order Amount',
                },
              ]}
              buttonClassName="p-lr-2"
              onSelect={v => form.setFieldValue('condition.type', v.value)}
              width="auto"
            />
          </FormGroup>
        )}
      </Field>

      {conditionType === 'item_quantity' && (
        <FastField name="condition.eligible_items">
          {({ field }: FieldProps) => (
            <FormGroup
              title="Eligible Item"
              help="Select item that will entitle the customer to free item. You may select the menus, categories or specific items"
              error={getFieldError(form, 'condition.eligible_items')}
            >
              <FormTreeSelect
                id={`eligible_items`}
                selected={normalizeTreeData(field.value)}
                nodes={menuTree}
                typePriority={['menu', 'category', 'dish']}
                onChange={(_, prioritizedSelected) =>
                  form.setFieldValue(
                    'condition.eligible_items',
                    prioritizedSelected
                  )
                }
              />
            </FormGroup>
          )}
        </FastField>
      )}

      <FastField name="condition.tiers">
        {({ field }: any) => {
          function hasEmptyFreeItems(array: any) {
            return array.some((tier: any) => Object.keys(tier.free_items).length === 0);
          }
          function hasMenu(array: any) {
            let count = 0;
            return array.some((tier: any) => {
              const freeItems = tier.free_items;
              const hasMenuCategoryDish = Object.keys(freeItems).some((key) => {
                if (freeItems[key] && freeItems[key].menu && freeItems[key].category && freeItems[key].dish) {
                  return freeItems[key].menu.length > 0 || freeItems[key].category.length > 0 || freeItems[key].dish.length > 0;
                }
                return false;
              });

              if (hasMenuCategoryDish) {
                count += 1;
              }
              return count === array.length;
            });
          }
          const hasEmpty = hasEmptyFreeItems(field.value) || !hasMenu(field.value);

          const errorMsg = hasEmpty ? "Free Items must have at least one of these keys: menu, category, dish" : "";
          return (
          <FormGroup
            help="Define choices that can contain a list of free items a customer can select from."
            error={errorMsg}
          >
            <Button
              type="button"
              color="primary-inverse"
              size="xs"
              className="m-b-2"
              onClick={() => {
                form.setFieldValue('condition.tiers', [
                  ...field.value,
                  {
                    id: nanoid(),
                    lower_limit: 0,
                    upper_limit: 0,
                    free_items: {},
                    free_quantity: 0,
                  } as T.Schema.Restaurant.FreeItemPromo.ConditionTiers,
                ]);
              }}
            >
              Add Tier
            </Button>
            <ConditionTiers
              r={restaurant}
              values={field.value || []}
              onChange={item => form.setFieldValue('condition.tiers', item)}
              form={form}
            />
          </FormGroup>
        )}}
      </FastField>

      <Field
        name="condition.deal"
        render={({ field }: any) => (
          <FormGroup
            optional={true}
            title="Walletly Deal"
            help="Select the deal to link this. Prior setup of a deal in Walletly is required."
          >
            <SelectAdv
              type="single"
              value={field.value}
              onChange={(options: string) => {
                form.setFieldValue('condition.deal', options);
              }}
              options={deals.map((deal: any) => {
                return {
                  label: deal.name,
                  value: deal._id,
                };
              })}
            />
          </FormGroup>
        )}
      />
    </div>
  );
}
