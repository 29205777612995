/* tslint:disable:object-literal-key-quotes */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Button } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalTitle, ModalContent } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { FormGroup } from "@lib/components";
import shortid from "shortid";

interface Props {}
interface State {
	loading: boolean;
	active: boolean;
}

@inject("store")
@observer
export class RestaurantMenuTemplates extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			active: false,
		};
	}

	createPizzaMenu = async () => {
		const menu_id = shortid.generate();

		const category1_id = shortid.generate();
		const category2_id = shortid.generate();
		const category3_id = shortid.generate();

		const dish1_id = shortid.generate();
		const dish2_id = shortid.generate();
		const dish3_id = shortid.generate();
		const dish4_id = shortid.generate();
		const dish5_id = shortid.generate();
		const dish6_id = shortid.generate();
		const dish7_id = shortid.generate();

		const optionset1_id = shortid.generate();
		const optionset2_id = shortid.generate();
		const dish9_id = shortid.generate();

		const menu: T.Schema.Restaurant.Menu.RestaurantMenu = {
			_id: menu_id,
			name: "Pizza Menu",
			display_name: "",
			description: "",
			conditions: {
				times: [],
				services: [],
				hours: [],
				pre_order: {
					enabled: false,
					days_in_advance: 0,
					cutoff_time: "",
				},
				age_restricted: false,
			},
			categories: [
				{
					_id: category1_id,
					menu_id: menu_id,
					name: "Pizzas",
					display_name: "",
					dishes: [
						{
							type: "standard",
							_id: dish1_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Magherita",
							display_name: "",
							price: 11.9,
							description:
								"Cheese, tomato & basil with tomato base",
							subtitle: "",
							image: {
								_id: "a5f571db-fbf5-4a4d-80e3-562e1cbf8f87",
								name: "pizza-margherita-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "Sk0rG2UWE",
									name: "Cheese",
									active: true,
								},
								{
									_id: "S1SUGhIb4",
									name: "Tomato",
									active: true,
								},
								{
									_id: "mirzQ-con",
									name: "Basil",
									active: true,
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
							option_set_primary: "",
						},
						{
							type: "standard",
							_id: dish2_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Pepperoni",
							display_name: "",
							price: 12.9,
							description:
								"Loaded with pepperonis and cheese on a tomato base",
							subtitle: "",
							image: {
								_id: "6f1e43bd-a365-46d7-9a86-063089271edd",
								name: "pizza-pepperoni-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "SJerI3IW4",
									name: "Cheese",
									active: true,
								},
								{
									_id: "ry_SU38-V",
									name: "Pepperoni",
									active: true,
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
							option_set_primary: "",
						},
						{
							type: "standard",
							_id: dish3_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Chicken",
							display_name: "",
							price: 14.9,
							description:
								"Seasoned chicken, corn, mushrooms and capsicum ",
							subtitle: "",
							image: {
								_id: "aacb1904-1925-4ace-87e4-5b15ff3c5a12",
								name: "pizza-chicken-corn-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "rkjKBnU-N",
									name: "Chicken",
									active: true,
								},
								{
									_id: "HkOcB3U-4",
									name: "Corn",
									active: true,
								},
								{
									_id: "SkCqB3UZV",
									name: "Mushroom",
									active: true,
								},
								{
									_id: "B1FornIWN",
									name: "Capsicum ",
									active: true,
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
						},
						{
							type: "standard",
							_id: dish4_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Veggie",
							display_name: "",
							price: 12.9,
							description:
								"Tomato and onion with light herb seasoning",
							subtitle: "",
							image: {
								_id: "4b533707-09ea-4e54-8db5-2dd2ba05a1f2",
								name: "veggie-pizza-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "ryx0Ph8-N",
									name: "Tomato Base",
									active: true,
								},
								{
									_id: "SkKAw2U-V",
									name: "Onion",
									active: true,
								},
								{
									_id: "r1lkO2U-4",
									name: "Pineapple",
									active: true,
								},
								{
									_id: "B15Juh8bE",
									name: "Capsicum",
									active: true,
								},
								{
									_id: "ByExun8bE",
									name: "Cheese",
									active: true,
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
						},
					],
				},
				{
					_id: category2_id,
					menu_id: menu_id,
					name: "Combo Deals",
					display_name: "",
					dishes: [
						{
							type: "combo",
							_id: shortid.generate(),
							menu_id: menu_id,
							category_id: category2_id,
							name: "2 Pizzas & 1 Side",
							display_name: "",
							description:
								"Grab any 2 pizzas and any side with this combo",
							subtitle: "",
							price: 29.95,
							tags: [],
							image: null,
							status: null,
							ingredients: [],
							option_sets: [],
							choices: [
								{
									_id: shortid.generate(),
									name: "First Pizza",
									dishes: [
										dish1_id,
										dish2_id,
										dish3_id,
										dish4_id,
									],
									selected: null,
									lpo: 11.9,
								},
								{
									_id: shortid.generate(),
									name: "Second Pizza",
									dishes: [
										dish1_id,
										dish2_id,
										dish3_id,
										dish4_id,
									],
									selected: null,
									lpo: 11.9,
								},
								{
									_id: shortid.generate(),
									name: "Side Dish",
									dishes: [dish5_id, dish6_id, dish7_id],
									selected: null,
									lpo: 4,
								},
							],
							price_type: "standard",
						},
					],
				},
				{
					_id: category3_id,
					menu_id: menu_id,
					name: "Sides",
					display_name: "",
					dishes: [
						{
							type: "standard",
							_id: dish5_id,
							menu_id: menu_id,
							category_id: category3_id,
							name: "Garlic Bread",
							display_name: "",
							price: 4.5,
							description: "",
							subtitle: "",
							image: {
								_id: "87612477-521b-4854-9068-d56adca0576c",
								name: "garlic-bread-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [],
							option_sets: [],
							price_type: "standard",
							choices: [],
						},
						{
							type: "standard",
							_id: dish6_id,
							menu_id: menu_id,
							category_id: category3_id,
							name: "Fries",
							display_name: "",
							price: 4,
							description: "",
							subtitle: "",
							image: {
								_id: "dc42d806-534b-4e51-a2c6-870440391524",
								name: "fries-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [],
							option_sets: [],
							price_type: "standard",
							choices: [],
						},
						{
							type: "standard",
							_id: dish7_id,
							menu_id: menu_id,
							category_id: category3_id,
							name: "Fried Chicken",
							display_name: "",
							price: 7,
							description: "",
							subtitle: "",
							image: {
								_id: "7ebd6e08-efec-4fe1-a2ac-58efdd21e371",
								name: "fried-chicken-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [],
							option_sets: [],
							price_type: "standard",
							choices: [],
						},
					],
				},
			],
		};

		const option_sets: T.Schema.Restaurant.Menu.RestaurantOptionSet[] = [
			{
				_id: optionset1_id,
				name: "Pizza Menu - Base Choice",
				display_name: "Base Choice",
				show_in_menu: false,
				conditions: {
					required: true,
					multi_select: false,
					quantity_select: false,
					min_options: "",
					max_options: "",
					free_amount: "",
				},
				options: [
					{
						_id: shortid.generate(),
						name: "Classic",
						quantity: 0,
						price: "",
						status: null,
					},
					{
						_id: shortid.generate(),
						name: "Thin",
						quantity: 0,
						price: "",
						status: null,
					},
					{
						_id: shortid.generate(),
						name: "Stuffed Crust",
						quantity: 0,
						price: "4.0",
						status: null,
					},
				],
			},
			{
				_id: optionset2_id,
				name: "Pizza Menu - Extra Toppings",
				display_name: "Extra Toppings",
				show_in_menu: false,
				conditions: {
					required: false,
					multi_select: true,
					quantity_select: false,
					min_options: "",
					max_options: "",
					free_amount: "",
				},
				options: [
					{
						_id: shortid.generate(),
						name: "Extra Onion",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Capsicum",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Mushroom",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Pineapple",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Jalapeños",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Olives",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Chicken",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Beef",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Pepperoni",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Bacon",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Prawns",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Seafood Mix",
						quantity: 0,
						price: 3,
					},
				],
			},
		];

		await this.saveRestaurant({
			process: (r) => {
				r.menus.push(menu);
				r.option_sets.push(option_sets[0]);
				r.option_sets.push(option_sets[1]);
				return {
					update: {
						$set: { menus: r.menus, option_sets: r.option_sets },
					},
				};
			},
			successMsg: "Menu template created",
			before: () => this.setState({ loading: true }),
			onSuccess: () => this.setState({ loading: false, active: false }),
			onFail: () => this.setState({ loading: false }),
			onError: () => this.setState({ loading: false }),
		});
	};

	render() {
		const { active, loading } = this.state;
		return (
			<div className="inline-block">
				<Button
					size="xxs"
					color="white"
					onClick={() => this.setState({ active: true })}
				>
					Templates
				</Button>
				<Modal
					active={active}
					width={440}
					close={() => this.setState({ active: false })}
				>
					<ModalTitle
						paddingtb={30}
						paddinglr={30}
						className="round-top-sm"
					>
						<h3 className="text-center flex-grow">
							Menu Templates
						</h3>
					</ModalTitle>
					<ModalContent paddingtb={30} paddinglr={30}>
						<FormGroup contentClassName="child-mb-15">
							<p className="lhp big">
								Pick from one of the ready made templates to
								help you get started building your menu.
							</p>
							<p className="lhp big">
								Choosing a template below will create a new
								menu, it will not overwrite any of your existing
								menus or items.
							</p>
						</FormGroup>
						<FormGroup>
							<Button
								full={true}
								color={"primary-inverse"}
								onClick={this.createPizzaMenu}
							>
								{loading && <RotateLoader size={2} />}
								{!loading && "Create Pizza Menu"}
							</Button>
						</FormGroup>
					</ModalContent>
				</Modal>
			</div>
		);
	}
}
