/// <reference types='@lib/uber/types' />

import * as React from "react";
import { inject, observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    Table, 
    TableRow,
    TableCell,
    TableBody,
    RotateLoader,
    Modal,
    ModalContent,
    ModalTitle,
    Button } from "@lib/components";
import { Quotation, QuotationCreateParams } from "@lib/uber/types/resources/Quotations";
import { MobxComponent } from '../../../../../mobx/components';
import { withTheme } from "styled-components";
import { UI } from '../../../../../core/ui';
import _ from "lodash";
import { parsePhoneNumberFromString}  from 'libphonenumber-js';

const GoogleMapKeys = [
	"route",
	"locality",
	"administrative_area_level_1",
	"postal_code",
	"country",
	"street_number"
]

interface Props extends WithTranslation {
	order: T.Schema.Order.OrderSchema;
  	restaurant: T.Schema.Restaurant.RestaurantSchema;
	active: boolean;
  	onClose: () => void;
}

interface State {
	quote: Quotation | null;
	error: string;
	loading: boolean;
	delivery_fee: number;
    previous_delivery_fee: number;
	tips: number;
    params: QuotationCreateParams | null;
}

@inject("store") @observer
class UberEstimationClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			quote: null,
			error: '',
			loading: false,
			delivery_fee: 0,
			tips: 0,
            previous_delivery_fee: 0,
            params: null
		}
	}

	
	// extend the interface of addressObj
	// for now, it supports only google maps
	getStructuredAddress(addressObj: T.Schema.Restaurant.RestaurantMapDataGoogleMaps["components"] | any) {
		let str;
		if (addressObj && _.every(Object.keys(addressObj), (key) => GoogleMapKeys.includes(key) )) {
			str = JSON.stringify({
				street_address: [`${addressObj.street_number} ${addressObj.route}]`],
				city: addressObj.locality,
				state: addressObj.administrative_area_level_1,
				zip_code: addressObj.postal_code,
				country: addressObj.country,
			})
		}
		return str;
	}


	async componentDidMount() {
		const { store } = this.injected;
        const restaurant = store.restaurant!;
		const orderConfig = store.order!.config!;
		const order = store.order!;
		this.setState({ loading: true });
		if(orderConfig.uber_order_id) {
			const responseDelivery = await store.api.get_uber_delivery( {
				restaurantId: restaurant._id,
				orderId: order._id,
				deliveryId: orderConfig.uber_order_id
			});

			if(responseDelivery.outcome == 1 ) {
				this.processEstimate();
			} else {
				const { delivery } = responseDelivery;
				if(['canceled', 'returned'].includes(delivery.status) ||
				delivery.undeliverable_action ||
				delivery.undeliverable_reason) {
					this.processEstimate();
				} else this.setState({loading: false, error: "Uber delivery already requested or is in progress."});
			}
		}  else {
			this.processEstimate();
		}


	}

	async processEstimate() {
		const { store } = this.injected;
        const restaurant = store.restaurant!;
		const location = restaurant.location;
		const orderConfig = store.order!.config!;
		const order = store.order!;
		const uber = restaurant.settings.services.delivery.providers.uber!;
		const pickup = location.map_data.components;
		
		this.setState({ loading: true, previous_delivery_fee: orderConfig.uber_total_fee ?? 0, tips: store.order?.payment.stripe_uber_tip ?? 0 })
		
		let formatPickupPhoneNumber =  parsePhoneNumberFromString(location.phone || uber.pickup_phone_number, 'AU')?.number ||
		parsePhoneNumberFromString(location.phone || uber.pickup_phone_number, 'NZ')?.number;

		let formatDropoffPhoneNumber: any = store.order?.customer.phone;
		if (formatDropoffPhoneNumber)
			formatDropoffPhoneNumber  = parsePhoneNumberFromString(formatDropoffPhoneNumber, 'AU')?.number || parsePhoneNumberFromString(formatDropoffPhoneNumber, 'NZ')?.number;

		let params = {
			dropoff_address: this.getStructuredAddress(orderConfig.address_components) ?? orderConfig.destination,
			pickup_address:  this.getStructuredAddress(pickup as any) ?? location.address,
			//make sure to format to e164 all phonenumbers before sending request
			pickup_phone_number: formatPickupPhoneNumber,
			dropoff_phone_number: formatDropoffPhoneNumber,
			
		} as QuotationCreateParams

		// TODO: When request delivery is done in Dashboard, assume it's a rebooking for delivery NOW. Original timings will be overriden.
		if (orderConfig.due === "now") {
			const res = await store.api.order_eta({
				service: orderConfig.service,
				due: orderConfig.due,
				time: orderConfig.time,
				date: orderConfig.date,
				driving_time: orderConfig.driving_time,
				restaurant_id: restaurant._id
			});
			if (res.outcome === 0 && res.ready_in_timestamp) {
				if ((res.ready_in_timestamp - (new Date()).valueOf()) >= 600000) {
					// ready time must be more than 10minutes from placement
					params.pickup_ready_dt = new Date(res.ready_in_timestamp).toISOString(); 
					params.dropoff_ready_dt = params.pickup_ready_dt;
				} else {
					// ready time is in less than 10 minutes
					params.pickup_ready_dt = new Date((new Date().valueOf() + 600000)).toISOString(); 
					params.dropoff_ready_dt = params.pickup_ready_dt;
				}
			}
		}

		// OVERRIDE REQUESTED DATE/TIME TO NOW (Only in Dashboard/Requesting New Delivery scenario)
		const timestamp = new Date().valueOf();
		if (orderConfig.due === "later" && timestamp && timestamp > 0) {
			params.pickup_ready_dt = (new Date(timestamp + 900000)).toISOString(); // RFC 3339 10mins minimum + buffer
			params.dropoff_ready_dt = params.pickup_ready_dt; // RFC 3339 10mins minimum + buffer
			params.dropoff_deadline_dt = (new Date(timestamp + 2100000)).toISOString(); // RFC 3339 20mins after ready time
		}
		orderConfig.timestamp = timestamp; // IMPORTANT FOR CREATING DELIVERY

		const response = await store.api.create_uber_quotation({
			restaurantId: restaurant._id,
			params,
		});

		if (response.outcome === 1) {
			this.setState({ error: response.message && response.message.toLowerCase().includes('invalid') ? 
			'Invalid phone number, please try again.': response.message, loading: false, quote: null });
			store.updateOrder({
				config: {
					...orderConfig,
					uber_error: 'Invalid phone number, please try again.',
					uber_quotation_id: ''
				}
			})
		} else if (response.quote) {
			//const delivery_fee = Number(commission_amount) + (response.quote.fee/100) + ((commission_percent/100) * (response.quote.fee/100))
			this.setState({ quote: response.quote, loading: false, params: params });
			this.setState({ delivery_fee: response.quote.computed_delivery_fee });
			store.updateOrder({
				config: {
					...orderConfig
				}
			});
		}
	}


    async processDelivery() {
        const { store } = this.injected;
		const restaurant = store.restaurant!;
		const order = store.order!;
		const orderConfig = order.config!;
		const { params, quote, delivery_fee } = this.state;

		this.setState({loading: true})
		const response = await store.api.create_uber_delivery({
			orderId: order._id,
			restaurantId: restaurant._id,
			quote: quote,
			quoteParams: params,
			args: {
				uber_previous_quote_amount: orderConfig.uber_quote_amount,
				uber_previous_total_fee: this.state.previous_delivery_fee * 100,

			}
		})

		if(response.outcome == 1) {
			this.setState({ error: response.message, loading: false});
        } else {
			store.updateOrder({
                config: {
                    ...orderConfig,
                    uber_quotation_id: quote!.id,
                    uber_quote_amount: quote!.fee / 100,
					uber_previous_quote_amount: orderConfig.uber_quote_amount,
                    uber_quote_payload: params,
                    uber_total_fee: delivery_fee,
					uber_previous_total_fee: this.state.previous_delivery_fee * 100,
                    uber_quote_expire: quote!.expires,
					uber_error: '',
					uber_delivery_error: ''
                } as T.Schema.Order.OrderConfig
            })
			UI.notification.success('Uber delivery created.');
			this.setState({ 
				error: '',
				params: null,
				quote: null,
				loading: false});
			this.props.onClose();
		}

    }

	render() {
		const { t, theme } = this.injected;
		const quote = this.state.quote;
		const error = this.state.error;
		const delivery_fee = this.state.delivery_fee;
		const tips = this.state.tips;
        const previous_delivery_fee = this.state.previous_delivery_fee;
        const { active, onClose } = this.props;
		return (
            <Modal
            width="sm"
            alignTop={true}
            active={active}
            close={onClose}
            >
                <ModalTitle>
                    <h4>{t("store.modals.checkout.delivery_estimation.title")}</h4>
                </ModalTitle>
                <ModalContent>
				{this.state.loading && <RotateLoader size={3} color={theme.colors.primary} />}

				{quote && (
					<Table>
						<TableBody>
							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.provider")}</TableCell>
								<TableCell className="small">Uber</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.estimated_fee")}</TableCell>
								<TableCell className="small">{t("currency", { value: delivery_fee })}</TableCell>
							</TableRow>
                            <TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.previous_estimated_fee")}</TableCell>
								<TableCell className="small">{t("currency", { value: previous_delivery_fee })}</TableCell>
							</TableRow>
							
							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.tips")}</TableCell>
								<TableCell className="small">{t("currency", { value: tips })}</TableCell>
							</TableRow>

							<TableRow className="no-border">
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.estimated_delivery_time")}</TableCell>
								<TableCell className="small">{quote.duration} minutes</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}

				{error && <div className="error-text" style={{ fontSize: '0.85rem' }}>{error}</div>}
                </ModalContent>
                {quote && !this.state.loading && (
                <ModalContent>
				<div className="p-3 flex-center">
					<Button 
						size="xs" 
						color="primary"
						className="m-r-2"
						onClick={() => this.processDelivery()}>
             			 {'Proceed'}
            		</Button>
					<Button size="xs" onClick={onClose}>
                        {'Cancel'}
            		</Button>
				</div>
                <small className="block">Note: When you proceed, the delivery fee difference will be shouldered by your resturant.</small>
                </ModalContent>
                )}

			</Modal>
		);
	}
}

// @ts-ignore
export const UberEstimation = withTheme(withTranslation()(UberEstimationClass));
